import { render, staticRenderFns } from "./CouponsShow.vue?vue&type=template&id=5010a0f6&scoped=true&"
import script from "./CouponsShow.vue?vue&type=script&lang=js&"
export * from "./CouponsShow.vue?vue&type=script&lang=js&"
import style0 from "./CouponsShow.vue?vue&type=style&index=0&id=5010a0f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5010a0f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VTab,VTabItem,VTabs})
